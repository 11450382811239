<template>
  <main class="main">
    <div class="content">
      <div class="container content__container content__container--w-1074">
        <div class="account-verification satefy">
          <div class="account-verification__head">
            <h1 class="account-verification__title">{{$t('faq')}}</h1>
          </div>
          <div class="account-verification__body">
            <div class="faq-body">
              <div class="faq-items">
                <div class="faq-item">
                  <div class="faq-item-top flex">
                    <div class="faq-item-title">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"/></svg>
                      {{$t('faq_question_1')}}
                    </div>
                    <div class="faq-item-arrow flex">
                      <svg height="20" viewbox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.68 19.12V10.52H0V8.6H8.68V0H10.68V8.6H19.36V10.52H10.68V19.12H8.68Z" fill="#F18842"></path></svg>
                    </div>
                  </div>
                  <div class="faq-item-cont" v-html="this.$t('faq_answer_1')">
                  </div>
                </div>
                <div class="faq-item">
                  <div class="faq-item-top flex">
                    <div class="faq-item-title">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M160 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h50.7L9.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L256 109.3V160c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H160zM576 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM448 208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM400 384a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm128 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM272 384a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48 80a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM144 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM576 336a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm-48-80a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
                      {{$t('faq_question_2')}}
                    </div>
                    <div class="faq-item-arrow flex">
                      <svg height="20" viewbox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.68 19.12V10.52H0V8.6H8.68V0H10.68V8.6H19.36V10.52H10.68V19.12H8.68Z" fill="#F18842"></path></svg>
                    </div>
                  </div>
                  <div class="faq-item-cont" v-html="this.$t('faq_answer_2')">
                  </div>
                </div>
                <div class="faq-item">
                  <div class="faq-item-top flex">
                    <div class="faq-item-title">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z"/></svg>
                      {{$t('faq_question_3')}}
                    </div>
                    <div class="faq-item-arrow flex">
                      <svg height="20" viewbox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.68 19.12V10.52H0V8.6H8.68V0H10.68V8.6H19.36V10.52H10.68V19.12H8.68Z" fill="#F18842"></path></svg>
                    </div>
                  </div>
                  <div class="faq-item-cont" v-html="this.$t('faq_answer_3')">
                  </div>
                </div>
                <div class="faq-item">
                  <div class="faq-item-top flex">
                    <div class="faq-item-title">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M416 176c0 97.2-93.1 176-208 176c-38.2 0-73.9-8.7-104.7-23.9c-7.5 4-16 7.9-25.2 11.4C59.8 346.4 37.8 352 16 352c-6.9 0-13.1-4.5-15.2-11.1s.2-13.8 5.8-17.9l0 0 0 0 .2-.2c.2-.2 .6-.4 1.1-.8c1-.8 2.5-2 4.3-3.7c3.6-3.3 8.5-8.1 13.3-14.3c5.5-7 10.7-15.4 14.2-24.7C14.7 250.3 0 214.6 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176zM231.5 383C348.9 372.9 448 288.3 448 176c0-5.2-.2-10.4-.6-15.5C555.1 167.1 640 243.2 640 336c0 38.6-14.7 74.3-39.6 103.4c3.5 9.4 8.7 17.7 14.2 24.7c4.8 6.2 9.7 11 13.3 14.3c1.8 1.6 3.3 2.9 4.3 3.7c.5 .4 .9 .7 1.1 .8l.2 .2 0 0 0 0c5.6 4.1 7.9 11.3 5.8 17.9c-2.1 6.6-8.3 11.1-15.2 11.1c-21.8 0-43.8-5.6-62.1-12.5c-9.2-3.5-17.8-7.4-25.2-11.4C505.9 503.3 470.2 512 432 512c-95.6 0-176.2-54.6-200.5-129zM228 72c0-11-9-20-20-20s-20 9-20 20V86c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V280c0 11 9 20 20 20s20-9 20-20V266.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V72z"/></svg>
                      {{$t('faq_question_4')}}
                    </div>
                    <div class="faq-item-arrow flex">
                      <svg height="20" viewbox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.68 19.12V10.52H0V8.6H8.68V0H10.68V8.6H19.36V10.52H10.68V19.12H8.68Z" fill="#F18842"></path></svg>
                    </div>
                  </div>
                  <div class="faq-item-cont" v-html="this.$t('faq_answer_4')">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { api } from "@/api.ts";
import TopNav from '@/components/TopNav/TopNav';
import ProfileMenu from '@/components/ProfileMenu/ProfileMenu';
import '@/assets/css/style_main.min.css';
import {commitToggleModal} from '@/store/main/mutations';
import {readUser} from '@/store/main/getters';
import axios from 'axios';
import {readUserFp} from '@/store/main/getters';
import {commitSetUser} from '@/store/main/mutations';
import VueQRCodeComponent from 'vue-qr-generator';

export default {
  components: {
    TopNav,
    ProfileMenu,
    VueQRCodeComponent,
  },
  name: 'Faq',
  data() { return {
 }},
 computed: {
   user() {
     return readUser(this.$store);
   },
 },
 methods: {
    
 },
 mounted() {
  $('.faq-item-top').on('click', function(){
    $(this).toggleClass('active');
    $(this).next().slideToggle();
  })
 }
}

</script>

<style scoped>

.flex {
    display: flex;
    flex-wrap: wrap;
}
.faq-body {
    padding: 30px 23px;
}
.faq-item {
  border: 1px solid rgba(126, 135, 177, 0.4);
  background: rgba(255,255,255,0.12);
  margin-bottom: 15px;
  border-radius: 6px;
  overflow: hidden;
}
.faq-item:last-child {
  margin: 0;
}
.faq-item-top {
  height: 60px;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid transparent;
}
.faq-item-arrow {
  width: 60px;
  height: 100%;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #7E87B1;
}
.faq-item-title {
  width: calc(100% - 80px);
  line-height: 1.2;
  padding: 0 33px;
}
.faq-item-title svg {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.faq-item-title svg path {
    fill: #fff;
}
.faq-item-arrow svg {
  transition: 300ms;
}
.faq-item-arrow svg path {
    fill: #fff;
}
.faq-item-cont {
  padding: 20px 33px;
  display: none;
  font-size: 13px;
}
.faq-item-cont p:not(:last-child) {
  margin-bottom: 22px;
}
.faq-item-top.active {
  background: rgba(255,255,255,0.08);
  border-color: #7E87B1;
}
.faq-item-top.active .faq-item-arrow svg {
  transform: rotate(-45deg);
}
@media(max-width: 650px) {
    .faq-item-top {
        font-size: 14px;
        height: auto;
    }
    .faq-item-title {
        padding: 15px 18px;
    }
    .faq-item-cont {
        padding: 20px 18px;
        font-size: 12px;
    }
}
  
</style>
